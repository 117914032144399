<template>
  <a-drawer title="同城明细" placement="right" :closable="false" v-model:visible="drawerShow" v-if="drawerShow" width="80%">
    <div>
      <a-form :model="searchForm" layout="inline">
        <a-form-item label="取送车">
          <a-select v-model:value="searchForm.transportType.value" style="width: 200px" :options="transportType"
            placeholder="请选择运输类型" />
        </a-form-item>
        <!--        <a-form-item label="运输类型">-->
        <!--          <a-input v-model:value="searchForm.lineName"/>-->
        <!--        </a-form-item>-->
        <a-form-item>
          <a-space>
            <a-button class="searchLoading-button" :loading="loading" type="primary" @click="onSearch"
              :disabled="loading">
              查询
            </a-button>
            <a-button @click="reset">
              重置
            </a-button>
          </a-space>
        </a-form-item>

      </a-form>
      <div style="height:15px"></div>
      <a-table size="small" :columns="columns" :row-key="record => record.id" :data-source="listData" bordered
        :pagination="pagination" :loading="loading" @change="handleTableChange">
        <template #operation="{ record }">
          <a @click="copyShow(record.id)">复制线路</a>
          <a-divider type="vertical" />
          <a @click="onAreaShow(2, record.id)">查看</a>
          <a-divider type="vertical" />
          <a @click="onAreaShow(3, record.id)">编辑</a>
        </template>
        <template #prescription="{ record }">
          <div v-if="record.idOptimal !== ''"> {{ record.prescription }}</div>
        </template>
        <template #remark="{ record }">
          <div v-if="record.idOptimal !== ''">{{ record.remark }}</div>
        </template>
        <template #transportType="{ record }">
          {{ record.transportType === '0' ? '地跑' : '' }}
          {{ record.transportType === '1' ? '小板车' : '' }}
        </template>
        <template #isDelivery="{ record }">
          {{ record.isDelivery === '1' ? '送车' : '' }}
          {{ record.isDelivery === '2' ? '取车' : '' }}
        </template>
        <template #optimal="{ record }">
          <a-popconfirm :title="`你确定选择${record.isOptimal ? '否' : '是'}最优线路？`" @confirm="adjustment(record)">
            <a-switch v-model:checked="record.isOptimal" checkedChildren="是" unCheckedChildren="否"
              @click="record.isOptimal = !record.isOptimal" />
          </a-popconfirm>
        </template>
        <template #cost="{ record }">
          <span v-if="record.lineOffer && record.lineOffer.value === 2">起步:{{ record.startingPrice }}元 超出{{
            record.exceedingKm
          }}公里,每公里:{{ record.perKmPrice }}元</span>
          <span v-else>{{ record.cost }}</span>
        </template>
      </a-table>
    </div>
    <a-modal v-model:visible="areaShow" footer="" width="600px" :destroyOnClose="true">
      <template #title>
        <span style="font-size:20px">编辑线路</span>
      </template>
      <a-form layout="vertical" ref="formRef" :model="addForm" :rules="rules">
        <a-form-item label="运输类型" :name="['transportType', 'value']">
          <a-select disabled="true" v-model:value="addForm.transportType.value"
            :options="$store.state.enumAll.transportType" placeholder="请选择运输类型" />
        </a-form-item>
        <a-form-item label="取送类型" :name="['isDelivery', 'value']">
          <a-select :disabled="disabled" v-model:value="addForm.isDelivery.value" :options="DeliveryEnum"
            placeholder="请选择取送类型" />
        </a-form-item>
        <a-form-item label="价格类型">
          <a-select :disabled="disabled" v-model:value="addForm.lineOffer.value" @change="onLineOffer"
            :options="$store.state.enumAll.lineOfferType" placeholder="请选择价格类型" />
        </a-form-item>
        <a-form-item label="" v-if="addForm.lineOffer.value === 1" name="cost">
          <a-input :disabled="disabled" v-if="addForm.lineOffer.value === 1" v-model:value="addForm.cost" type="number"
            :min="1" placeholder="请填写一口价" />
        </a-form-item>
        <a-form-item label="" v-if="addForm.lineOffer.value === 2">
          <div class="startingPrice">
            <a-input :disabled="disabled" class="input-startingPrice" v-model:value="addForm.startingPrice" type="number"
              :min="1" placeholder="" />
            起步，超出
            <a-input :disabled="disabled" class="input-startingPrice" v-model:value="addForm.exceedingKm" type="number"
              :min="1" placeholder="" />
            公里，每公里
            <a-input :disabled="disabled" class="input-startingPrice" v-model:value="addForm.perKmPrice" type="number"
              :min="1" placeholder="" />
            元
          </div>
        </a-form-item>
        <a-form-item>
          <template #label><span style="color:#f00">*&nbsp;</span>线路名称</template>
          <div style="display:flex;justify-content: space-between;">
            <a-cascader disabled="true" :disabled="disabled" v-model:value="startAddress"
              :options="$store.state.app.cityTypeDict" :show-search="{ filter }" :changeOnSelect="true"
              @change="setStartAddress" placeholder="请选择起点" style="width:552px" />

          </div>
        </a-form-item>
        <a-form-item label="" v-if="addForm.lineOffer.value > 0">
          <a-checkbox-group v-model:value="value">
            <a-row :gutter="[24, 6]">
              <a-col :span="8" v-for="(item, index) in startingAddress" :key="index">
                <view style="display: flex;">
                  <a-checkbox :disabled="disabled" :value="index">{{ item.label }}</a-checkbox>
                  <a-input :disabled="disabled" v-if="value.indexOf(index) != -1 && addForm.lineOffer.value === 1"
                    style=" width: 40%;height: 26px" size="small" compact v-model:value="item.cost" type="number" :min="1"
                    placeholder="请填写一口价" />
                </view>
              </a-col>
            </a-row>
          </a-checkbox-group>
        </a-form-item>
        <a-form-item label="时效" name="prescription">
          <a-input :disabled="disabled" v-model:value="addForm.prescription" type="number" :min="1" placeholder="请填写时效" />
        </a-form-item>
        <a-form-item label="线路标签">
          <a-select :disabled="disabled" v-model:value="addForm.lineLabelName" @change="onLineLabel"
            :options="$store.state.enumAll.Label" placeholder="请选择线路标签" />
        </a-form-item>
        <a-form-item label="备注">
          <a-textarea :disabled="disabled" v-model:value="addForm.remark" />
        </a-form-item>
      </a-form>
      <div style="display:flex;justify-content: flex-end;margin-top:15px">
        <a-button size="large" style="margin-right:15px" @click="areaShow = false">取消</a-button>
        <a-button size="large" class="searchLoading-button" @click="addData">
          确定
        </a-button>
      </div>
    </a-modal>

    <a-modal v-model:visible="seeShow" footer="" width="600px" :destroyOnClose="true">
      <template #title>
        <span style="font-size:20px">查看线路</span>
      </template>
      <a-row :gutter="[24, 12]">
        <a-col :span="12">
          <span class="seeTitle">运输类型：</span>
          <span class="seeContent" v-if="addForm.transportType !== null"> {{ addForm.transportType.label }}</span>
        </a-col>
        <a-col :span="12">
          <span class="seeTitle">取送类型： </span>
          <span class="seeContent">{{ addForm.isDelivery.label }}</span>
        </a-col>
        <a-col :span="24">
          <span class="seeTitle"> 价格类型：</span>
          <span class="seeContent" v-if="addForm.lineOffer !== null"> {{ addForm.lineOffer.label }}
            <span class="seeContent" v-if="addForm.lineOffer.value === 1">{{ addForm.cost }} 元</span>
            <span class=" seeContent" v-if="addForm.lineOffer.value === 2">
              {{ addForm.startingPrice }}
              元起步，超出
              {{ addForm.exceedingKm }}
              公里，每公里
              {{ addForm.perKmPrice }}
              元
            </span>
          </span>
        </a-col>
        <a-col :span="24">
          <span class="seeTitle">线路名称：</span>
          <span class="seeContent"> {{ addForm.startProvinceName }}{{ addForm.startCityName }}</span>
        </a-col>
        <a-col :span="24" v-if="addForm.lineOffer.value > 0">
          <a-row :gutter="[24, 12]" class="seeContent seeContentB">
            <a-col v-show="value.indexOf(index) !== -1" :span="8" v-for="(item, index) in startingAddress" :key="index">
              <view style="display: flex;">
                {{ item.label }}
                <span v-if="addForm.lineOffer.value === 1"> {{ item.cost }}</span>
              </view>
            </a-col>
          </a-row>
        </a-col>
        <a-col :span="12">
          <span class="seeTitle">最优线路：</span>
          <span class="seeContent" v-if="addForm.isOptimal !== null">{{ addForm.isOptimal.label }}</span>
        </a-col>
        <a-col :span="12">
          <span class="seeTitle">时效：</span>
          <span class="seeContent"> {{ addForm.prescription }}天</span>
        </a-col>
        <a-col :span="12">
          <span class="seeTitle">线路标签：</span>
          <span class="seeContent"> {{ addForm.lineLabelName }}</span>
        </a-col>
        <a-col :span="12">
          <span class="seeTitle">备注：</span>
          <span class="seeContent">{{ addForm.remark }}</span>
        </a-col>
      </a-row>
      <!--      <div style="display:flex;justify-content: flex-end;margin-top:15px">-->
      <!--        <a-button size="large" style="margin-right:15px" @click="seeShow=false">取消</a-button>-->
      <!--      </div>-->
    </a-modal>
    <a-modal v-model:visible="carrierChooseShow" title="复制线路" @ok="confirmCopy" :confirmLoading="loading">
      <div>
        <div style="color:#f00;font-size: 12px;">注：搜索并选择承运商单击确认即可复制线路</div>
        <a-select v-model:value="carrierId" style="width:100%" placeholder="搜索承运商" @search="fetchCarrier"
          :filterOption="false" show-search>
          <template v-if="fetching" #notFoundContent>
            <a-spin size="small" />
          </template>
          <a-select-option v-for="item in carriers" :key="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
    </a-modal>

  </a-drawer>
</template>
<script>
import { reactive, ref, toRefs,onMounted } from 'vue'
import HxTable from '@/components/HxTable'
import { useStore } from 'vuex'
import { fuzzy } from '@/api/crm/carrer/carrier'
import { strongholdOptimalLineNamePage, detail, edit, optimal, copyLine } from '@/api/carrier/line'
import { message } from 'ant-design-vue'

export default {
  props: {},
  components: {
    HxTable
  },
  setup (props) {
    const store = useStore()
    const formRef = ref()
    const state = reactive({
      carriers: [],
      carrierId: '',
      loading: false,
      carrierId: null,
      fetching: false,
      timeOutId: null,
      carrierChooseShow: false,
      drawerShow: false,
      disabled: false,
      seeShow: false,
      areaShow: false,
      editShow: false,
      loading: true,
      id: '',
      lineId: '',
      value: [],
      startingAddress: [],
      listData: [],
      editCarrier: {},
      addForm: {
        lineOffer: {},
        isDelivery: {},
        isOptimal: {},
        transportType: {}
      },
      carrierData: {},
      CarrierList: [],
      startAddress: [],
      searchForm: {
        transportType: {},
      },
      mirrorSearchForm: {},
      columns: [
        {
          title: '线路名称',
          dataIndex: 'lineName',
          align: 'center'
        },
        {
          title: '承运商',
          dataIndex: 'carrierName',
          align: 'center'
        },
        {
          title: '价格类型',
          dataIndex: 'lineOffer.label',
          align: 'center'
        },
        {
          title: '报价',
          dataIndex: 'cost',
          slots: {
            customRender: 'cost'
          }
        },
        {
          title: '时效',
          dataIndex: 'prescription',
          align: 'center'
        },
        {
          title: '是否最优线路',
          dataIndex: 'optimal',
          align: 'center',
          slots: {
            customRender: 'optimal'
          }
        },
        {
          title: '运输类型',
          dataIndex: 'transportType.label',
          align: 'center'
        },
        {
          title: '取送类型',
          dataIndex: 'isDelivery.label',
          align: 'center'
        },
        {
          title: '区域',
          dataIndex: 'areaNames',
          align: 'center',
          width: '15%'
        },
        {
          title: '线路标签',
          dataIndex: 'lineLabelName',
          align: 'center'
        },
        {
          title: '备注',
          dataIndex: 'remark',
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          align: 'center',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      cityList: [],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      transportType: [
        {
          value: '',
          label: '全部'
        },
        {
          value: 0,
          label: '地跑'
        },
        {
          value: 1,
          label: '小板'
        }],
      DeliveryEnum: [
        {
          label: '取车',
          value: 2
        },
        {
          label: '送车',
          value: 1
        }]
    })
    onMounted(() => {
      state.mirrorSearchForm = JSON.stringify(state.searchForm)
    })
    const onSearch = () => {
      state.loading = true
      loadData()
    }
    const copyShow = id => {
      state.carrierChooseShow = true
      state.lineId = id
      state.carrierId = ''
    }
    const confirmCopy = () => {
      state.loading = true
      copyLine({
        carrierId: state.carrierId,
        carrierLineId: state.lineId
      }).then(res => {
        if (res.code === 10000) {
          state.carrierChooseShow = false
          let ids = state.id.split(',')
          ids.push(res.data)
          state.id = ids.join(',')
          console.log(state.id);
          message.success('线路复制成功')
          state.pagination.current = 1
          loadData()
        }
      }).finally(() => { state.loading = false })
    }
    const loadData = () => {
      state.loading = true
      strongholdOptimalLineNamePage({
        ...state.searchForm,
        ids: state.id,
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data
        }
      }).catch(err => {
        console.log('分页错误   ' + err)
      }).finally(() => {
        state.loading = false
      })
    }
    const fetchCarrier = value => {
      if (value.length > 1) {
        state.fetching = true
        if (state.timeOutId !== null) {
          clearTimeout(state.timeOutId)
        }
        state.timeOutId = setTimeout(() => {
          fuzzy({ name: value }).then(res => {
            if (res.code === 10000) {
              state.carriers = res.data
            } else state.carriers = []
            state.fetching = false
          })
        }, 1000)
      } else {
        state.carriers = []
      }
    }
    const handleTableChange = (pag, filters, sorter) => {
      state.pagination = pag
      // loadData()
    }
    // 重置
    const reset = () => {
      state.searchForm = JSON.parse(state.mirrorSearchForm)
      loadData()
    }

    const setStartAddress = (value, selectedOptions) => {
      state.addForm.startProvince = value[0]
      state.addForm.startProvinceName = selectedOptions[0].label

      state.addForm.endProvince = value[0]
      state.addForm.endProvinceName = selectedOptions[0].label
      state.value = []
      if (selectedOptions.length > 1) {
        state.addForm.startCity = value[1]
        state.addForm.startCityName = selectedOptions[1].label
        state.endAddress = value
        state.addForm.endCity = value[1]
        state.addForm.endCityName = selectedOptions[1].label
        onCityList(value[0], value[1])
      } else {
        state.addForm.endCity = null
        state.addForm.endCityName = null
        state.addForm.startCity = null
        state.addForm.startCityName = null
        state.startingStoreAddress = []
      }
      if (selectedOptions.length > 2) {
        state.startingAddress = null
        state.value = null
        state.addForm.startArea = value[2]
        state.addForm.startAreaName = selectedOptions[2].label
        state.endAddress = value
        state.addForm.endArea = value[2]
        state.addForm.endAreaName = selectedOptions[2].label
        state.addForm.endCity = value[1]
        state.addForm.endCityName = selectedOptions[1].label
        state.addForm.endProvince = value[0]
        state.addForm.endProvinceName = selectedOptions[0].label
      } else {
        state.addForm.endAreaName = null
        state.addForm.endArea = null
        state.addForm.startArea = null
        state.addForm.startAreaName = null
      }
    }
    // 模糊搜索
    const filter = (inputValue, path) => {
      console.log('inputValue, path', inputValue, path)
      return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
    }
    // 模糊搜索
    const onDrawerShow = (r) => {
      state.drawerShow = r
      loadData()
    }
    // 打开区
    const onAreaShow = (e, id) => {
      switch (e) {
        case 2:
          state.lineNameType = '查看'
          state.lineNameButtonType = '查看'
          state.disabled = true
          state.lineTypeState = true
          onDetail(id)
          state.seeShow = true
          break
        case 3:
          state.lineNameType = '编辑'
          state.lineNameButtonType = '保存'
          state.disabled = false
          state.lineTypeState = true
          onDetail(id)
          state.areaShow = true
          break
        default:
          break
      }
    }
    // 线路详情
    const onDetail = (id) => {
      detail(id).then(res => {
        if (res.code === 10000) {
          console.log('res', res)

          state.addForm = res.data
          state.carrierId = res.data.carrierId
          state.startAddress = [state.addForm.startProvince + '', state.addForm.startCity + '', state.addForm.startArea + '']
          state.routeList = res.data.passPoint
          onCityList(state.addForm.startProvince + '', state.addForm.startCity + '')
          if (state.addForm.lineOffer === null) {
            state.addForm.lineOffer = {
              value: ''
            }
          }
          if (state.addForm.transportType === null) {
            state.addForm.transportType = {
              value: ''
            }
          }
          if (state.addForm.isDelivery === null) {
            state.addForm.isDelivery = {
              value: ''
            }
          }
        }
      }).catch(err => {
        console.log('查看详情错误' + err)
      })
    }
    // 遍历城市获取区
    const onCityList = (p, c) => {
      if (state.cityList.length < 1) {
        state.cityList = store.state.app.cityTypeDict
      }
      state.startingAddress = []
      state.cityList.forEach(e => {
        if (e.value === p) {
          e.children.forEach(d => {
            if (d.value === c) {
              state.startingAddress = state.startingAddress.concat(d.children)
              e.cost = ''
            }
          })
        }
      })
      state.value = []
      if (state.addForm.subLines != null && state.addForm.subLines.length > 0) {
        state.startingAddress.forEach((e, index) => {
          for (let i = 0; i < state.addForm.subLines.length; i++) {
            if (e.value === state.addForm.subLines[i].endArea + '') {
              e.cost = state.addForm.subLines[i].cost
              state.value.push(index)
            }
          }
        })
      }
    }
    // / 表单确认
    const addData = () => {
      formRef.value.validate().then(() => {
        if (state.addForm.lineOffer && state.addForm.lineOffer.value === 2) {
          if (state.addForm.startingPrice === undefined || state.addForm.startingPrice === '' || state.addForm.exceedingKm === undefined || state.addForm.exceedingKm === '' || state.addForm.perKmPrice === undefined || state.addForm.perKmPrice === '') {
            message.error('请填写公式价的内容')
            return
          }
        }
        if (Number(state.addForm.startCity) === Number(state.addForm.endCity)) {
          if (state.addForm.businessType.value !== 3) {
            message.error('业务类型该为同城')
            return
          }
        }
        if (state.addForm.startCity === undefined || state.addForm.startCity === null) {
          message.error('必须选择市')
          return
        }

        if (state.addForm.endCity !== '' && state.addForm.endCity !== undefined && state.addForm.startCity !== '' && state.addForm.startCity !== undefined) {
          state.addForm.passPoint = state.routeList
          state.addForm.carrierId = props.carrierId
          const subLines = []
          state.value.forEach(e => {
            if (e != null) {
              const d = state.startingAddress[e]
              const data = {
                cost: d.cost,
                endArea: d.value,
                endAreaName: d.label,
                endCity: state.addForm.startCity,
                endCityName: state.addForm.startCityName,
                endProvince: state.addForm.startProvince,
                endProvinceName: state.addForm.startProvinceName,
                remark: ''
              }
              subLines.push(data)
            }
          })
          if (subLines.length < 1) {
            message.error('同城至少选择一个区')
            return
          }
          state.addForm.subLines = subLines
          state.addForm.lineType = {}
        }
        if (state.addForm.startArea !== null) {
          state.addForm.startingStoreSubLines = null
        }
        if (state.addForm.endArea !== null) {
          state.addForm.endStoreSubLines = null
        }
        if (state.addForm.lineOffer.value === 2) {
          state.addForm.subLines.forEach(e => {
            e.cost = null
          })
        }
        onEdit()
      })
      // 线路编辑
      const onEdit = () => {
        console.log('carrierId', state.addForm.carrierId)
        edit(state.carrierId, { ...state.addForm }).then(res => {
          if (res.code === 10000) {
            message.success(res.data)
            loadData()
            onFormEmpty()
          }
        }).catch(err => {
          console.log('编辑错误' + err)
        })
      }
      const onFormEmpty = () => {
        state.addForm = {
          cost: '',
          remark: '',
          prescription: '',
          startAddress: {},
          endAddress: {},
          businessType: {},
          lineOffer: {},
          transportType: {},
          isDelivery: {},
          isOptimal: {
            value: 0
          },
          lineType: {}
        }
        state.startAddress = []
        state.endAddress = []
        state.startingAddress = []
        state.value = []
        state.routeList = []
        state.areaShow = false
        this.cityList = store.state.app.cityTypeDict
        // this.cityList.forEach(e => {
        //   e.cost = ''
        // })
      }
    }
    const adjustment = (e) => {
      e.isOptimal = !e.isOptimal
      if (e.isOptimal) {
        e.isOptimal = 1
      } else {
        e.isOptimal = 0
      }
      optimal(e.carrierId, e).then(res => {
        if (res.code === 10000) {
          loadData()
        }
      }).catch(err => {
        console.log('编辑错误' + err)
      })
    }

    return {
      ...toRefs(state),
      reset,
      onSearch,
      loadData,
      copyShow,
      confirmCopy,
      fetchCarrier,
      handleTableChange,
      setStartAddress,
      filter,
      onDrawerShow,
      onAreaShow,
      onDetail,
      addData,
      formRef,
      onCityList,
      adjustment
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
