<template>
  <HxTable v-model:columns="columns" :reload="loadData">
    <template #search>
      <a-form :model="searchForm" layout="inline">
        <!--        <a-form-item label="业务类型">-->
        <!--          <a-select allowClear style="width:200px" :disabled="disabled" v-model:value="searchForm.businessType.value"-->
        <!--                    :options="$store.state.enumAll.businessType" placeholder="请选择业务类型"/>-->
        <!--        </a-form-item>-->
        <a-form-item label="">
          <a-input v-model:value="searchForm.lineName" placeholder="线路名称" />
        </a-form-item>
        <a-form-item label="">
          <a-cascader v-model:value="searchForm.start" :options="$store.state.app.cityDict" :show-search="{ filter }"
            :changeOnSelect="true" @change="setSearchFormStartAddress" placeholder="同城" />
        </a-form-item>
        <!--        <a-form-item label="取送车">-->
        <!--          <a-input v-model:value="searchForm.lineName"/>-->
        <!--        </a-form-item>-->
        <!--        <a-form-item label="运输类型">-->
        <!--          <a-input v-model:value="searchForm.lineName"/>-->
        <!--        </a-form-item>-->
        <a-form-item>
          <a-space>
            <a-button class="searchLoading-button" :loading="loading" type="primary" @click="onSearch"
              :disabled="loading">
              查询
            </a-button>
            <a-button @click="reset">
              重置
            </a-button>
          </a-space>
        </a-form-item>

      </a-form>
    </template>

    <a-table size="small" :columns="columns" :row-key="record => record.id" :data-source="listData" bordered
      :pagination="pagination" :loading="loading" @change="handleTableChange">
      <template #optimal="{ record }">
        <a-tooltip>
          <template #title>{{ record.carrierIdList }}</template>
          <div v-if="record.carrierIdList != null" class="vehicles">
            <div v-for="(item, index) in record.carrierIdList.split(',')" :key="index">
              {{ item }}
            </div>
          </div>
        </a-tooltip>
      </template>
      <template #operation="{ record }">
        <a @click="onLineNameDetailedShow(record)">明细</a>
      </template>
      <template #prescription="{ record }">
        <div v-if="record.idOptimal !== ''"> {{ record.prescription }}</div>
      </template>
      <template #remark="{ record }">
        <div v-if="record.idOptimal !== ''">{{ record.remark }} </div>
      </template>
      <template #transportType="{ record }">
        {{ record.transportType === 0 ? '地跑' : '' }}
        {{ record.transportType === 1 ? '小板车' : '' }}
      </template>
      <template #isDelivery="{ record }">
        {{ record.isDelivery === 1 ? '送车' : '' }}
        {{ record.isDelivery === 2 ? '取车' : '' }}
      </template>
    </a-table>
  </HxTable>
  <TransportPage ref="transportPage"></TransportPage>
</template>
<script>
import { onMounted, reactive, toRefs, ref } from 'vue'
import HxTable from '@/components/HxTable'
import TransportPage from './transportPage'
import { strongholdOptimalPage } from '@/api/carrier/line'
import { enterprise } from '@/api/system/setting'

export default {
  components: {
    HxTable,
    TransportPage
  },
  setup () {
    const transportPage = ref()
    const state = reactive({
      editShow: false,
      loading: true,
      lineName: '',
      listData: [],
      editCarrier: {},
      carrierData: {},
      CarrierList: [],
      searchForm: {
        startAddress: {},
        endAddress: {},
        businessType: {},
        charge: '',
        chargeMobile: ''
      },
      columns: [
        {
          title: '线路名称',
          dataIndex: 'lineName',
          align: 'center'
        },
        {
          title: '承运商总数量',
          dataIndex: 'sum',
          align: 'center'
        },
        {
          title: '承运商',
          dataIndex: 'carrierName',
          align: 'center'
        },
        {
          title: '线路条数',
          dataIndex: 'lineNum',
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          align: 'center',
          slots: {
            customRender: 'operation'
          }
        }
      ],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    const onSearch = () => {
      state.loading = true
      state.pagination.current = 1
      loadData()
    }

    const loadData = () => {
      state.loading = true
      strongholdOptimalPage({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.total = res.data.total
          state.pagination.current = res.data.current
        }
      }).catch(err => {
        console.log('分页错误   ' + err)
      })
        .finally(() => {
          state.loading = false
        })
    }

    const handleTableChange = (pag, filters, sorter) => {
      state.pagination.current = pag.current
      state.pagination.pageSize = pag.pageSize
      loadData()
    }
    // 重置
    const reset = () => {
      state.searchForm = {
        startAddress: {},
        endAddress: {},
        businessType: {},
        charge: '',
        chargeMobile: ''
      }
      loadData()
    }

    onMounted(() => {
      loadData()
    })
    // 打开明细
    const onLineNameDetailedShow = (r) => {
      transportPage.value.id = r.id
      setTimeout(() => {
        transportPage.value.onDrawerShow(true)
      }, 10)
    }
    const setSearchFormStartAddress = (value, selectedOptions) => {
      state.searchForm.startProvince = value[0]
      if (selectedOptions.length > 1) {
        state.searchForm.startCity = value[1]
      } else {
        state.searchForm.startCity = null
      }
      if (selectedOptions.length > 2) {
        state.searchForm.startArea = value[2]
      } else {
        state.searchForm.startArea = null
      }
    }

    return {
      ...toRefs(state),
      reset,
      onSearch,
      loadData,
      handleTableChange,
      onLineNameDetailedShow,
      transportPage,
      setSearchFormStartAddress
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
